import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Markdown from 'markdown-to-jsx';

import { useQuestionnaireContext } from '../../context';
import { SummarySectionDef } from '../../dynamic-form/types';
import Button from '../../shared/Button';
import SummaryCard from '../../shared/SummaryCard';
import VStack from '../../shared/VStack';

import DynamicQuestionnairePage from '../DynamicQuestionnairePage';

export type SummarySectionProps = {
  def: SummarySectionDef;
};

export default function SummarySection(props: SummarySectionProps): JSX.Element {
  const { def } = props;

  const { strings, setPage, translate } = useQuestionnaireContext();

  const { cards, emptyText, pageKey, title } = def;
  const { edit } = strings.shared;

  function onEditClick() {
    setPage(<DynamicQuestionnairePage key={pageKey} pageKey={pageKey} />);
  }

  return (
    <VStack gap="24px">
      <Typography variant="h5">
        <Markdown>{translate(title)}</Markdown>
      </Typography>
      {cards.length === 0 && (
        <Box>
          {emptyText !== undefined && (
            <Typography variant="body2">
              <Markdown>{translate(emptyText)}</Markdown>
            </Typography>
          )}
          <Button variant="outlined" onClick={onEditClick}>{translate(edit)}</Button>
        </Box>
      )}
      {cards.map((cardDef) => (
        <SummaryCard
          button={{
            label: translate(edit),
            onClick: onEditClick,
          }}
          def={cardDef}
        />
      ))}
      <Divider />
    </VStack>
  );
}
