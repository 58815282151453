import { ComponentClassDef } from '../../types';
import { localizeMonthYear, makeLanguageIndependentString } from '../../utils';

import MonthYear from './MonthYear';

const monthYearClassDef: ComponentClassDef = {
  componentClass: MonthYear,
  defaultHtmlValue: null,
  fromHtmlValue(htmlValue) {
    if (!(htmlValue instanceof Date) || isNaN(htmlValue as unknown as number)) {
      // Invalid date
      return {
        htmlValue,
        // TODO | Make humanValue optional
        humanValue: makeLanguageIndependentString(''),
      };
    }

    return {
      htmlValue,
      humanValue: localizeMonthYear(htmlValue),
      typedValue: (htmlValue.getFullYear() * 12) + htmlValue.getMonth() + 1,
    };
  },
};

export default monthYearClassDef;
