
export type HtmlValue =
  | Date
  | null
  | string
  | string[];

export type HtmlValues = Record<any, HtmlValue>;

export enum Language {
  ENGLISH = 'en',
  SPANISH = 'es',
}

export interface LanguageOption {
  key: Language;
  label: string;
}

export type LanguageString = {
  [key in Language]: string;
};

export type Strings = Record<string, Record<string, LanguageString>>;

export type TypedValue =
  | Date
  | boolean
  | number
  // CHECKBOX_SELECT may have typedValues of number or string
  | (number | string)[]
  | string;

export interface Value {
  htmlValue: HtmlValue;
  humanValue: LanguageString;
  typedValue?: TypedValue;
}

export type Values = Record<string, Value>;
