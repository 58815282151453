import { FunctionComponent } from 'react';

import { ReactComponent as biologicalAgents } from './biological-agents.svg';
import { ReactComponent as chemicalGasAndMist } from './chemical-gas-and-mist.svg';
import { ReactComponent as dust } from './dust.svg';
import { ReactComponent as fumes } from './fumes.svg';
import { ReactComponent as oxygenDeficient } from './oxygen-deficient.svg';
import { ReactComponent as oxygenEnriched } from './oxygen-enriched.svg';
import { ReactComponent as smoke } from './smoke.svg';
import { ReactComponent as vapors } from './vapors.svg';

const SELECT_ICONS: Record<string, FunctionComponent> = {
	BIOLOGICAL_AGENTS: biologicalAgents,
	CHEMICAL_GAS_AND_MIST: chemicalGasAndMist,
	DUST: dust,
	FUMES: fumes,
	OXYGEN_DEFICIENT: oxygenDeficient,
	OXYGEN_ENRICHED: oxygenEnriched,
	SMOKE: smoke,
	VAPORS: vapors,
};

export { SELECT_ICONS };
