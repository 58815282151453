import allRespirators from './all-respirators.png';
import medicalRecord from './medical-record.png';
import n95 from './n95.png';
import personalInformation from './personal-information.png';
import review from './review.png';
import submit from './submit.png';

export type BackgroundTile = {
  src: string;
  logicalHeight: number,
  logicalWidth: number,
  tileHeight: number;
  tileWidth: number;
};

export const BACKGROUND_TILES: Record<string, BackgroundTile> = {
  ALL_RESPIRATORS: {
    logicalHeight: 790,
    logicalWidth: 982,
    src: allRespirators,
    tileHeight: 5,
    tileWidth: 5,
  },
  MEDICAL_RECORD: {
    logicalHeight: 158,
    logicalWidth: 153,
    src: medicalRecord,
    tileHeight: 1,
    tileWidth: 1
  },
  N95: {
    logicalHeight: 169,
    logicalWidth: 214,
    src: n95,
    tileHeight: 1,
    tileWidth: 1,
  },
  PERSONAL_INFORMATION: {
    logicalHeight: 158,
    logicalWidth: 157,
    src: personalInformation,
    tileHeight: 1,
    tileWidth: 1,
  },
  REVIEW: {
    logicalHeight: 129,
    logicalWidth: 163,
    src: review,
    tileHeight: 1,
    tileWidth: 1,
  },
  SUBMIT: {
    logicalHeight: 153,
    logicalWidth: 128,
    src: submit,
    tileHeight: 1,
    tileWidth: 1,
  },
};
