import categoryApr from './category-apr.png';
import categoryDisposable from './category-disposable.png';
import categoryPapr from './category-papr.png';
import categorySar from './category-sar.png';
import categoryScba from './category-scba.png';

export const RESPIRATOR_IMAGES: Record<string, string> = {
  'CATEGORY_APR': categoryApr,
  'CATEGORY_DISPOSABLE': categoryDisposable,
  'CATEGORY_PAPR': categoryPapr,
  'CATEGORY_SAR': categorySar,
  'CATEGORY_SCBA': categoryScba,
  // TODO | Get real images for individual types
  'TYPE_FULL_APR': categoryApr,
  'TYPE_HALF_APR': categoryApr,
  'TYPE_DISPOSABLE': categoryDisposable,
  'TYPE_FULL_PAPR': categoryPapr,
  'TYPE_HALF_PAPR': categoryPapr,
  'TYPE_FULL_SAR': categorySar,
  'TYPE_HALF_SAR': categorySar,
  'TYPE_HEAVY_SCBA': categoryScba,
  'TYPE_LIGHT_SCBA': categoryScba,
};
