import { ReactElement } from 'react';
import { UseFormSetValue } from 'react-hook-form';

import { UseFieldProps } from '../useField';

import { HtmlValue, HtmlValues, LanguageString, Value, Values } from '../../types';

import { RenderContext, Validator } from './common';
import { FunctionExpression } from './functions';
import { JSONObject } from './json';
import { SummaryCardDef, SummaryItemDef } from './summary';

export interface ComponentDef {
  _schema: ComponentSchema;
  key: string;
  props: ComponentProps;
  type: ComponentType;
}

export type ComponentClassDef = {
  componentClass: (props: any) => ReactElement;
  defaultHtmlValue?: HtmlValue;
  fromHtmlValue?: (htmlValue: HtmlValue, component: ComponentDef) => Value;
  getSubcomponents?: (component: ComponentDef) => ComponentDef[];
  getSummaryCards?: (component: ComponentDef, values: Values) => SummaryCardDef[];
  getSummaryItems?: (component: ComponentDef, values: Values) => SummaryItemDef[];
  renderProps?: (schema: ComponentSchema, context: RenderContext) => ComponentProps;
};

export type ComponentClassDefs = Record<string, ComponentClassDef>;

export interface ComponentProps {
  key: string;
  [key: string]: any;
}

export interface ComponentSchema extends Includable, JSONObject {
  key: string;
  props?: JSONObject;
  summary?: ComponentSummarySchema;
  type: ComponentType;
}

export interface ComponentSummarySchema extends JSONObject {
  label?: LanguageString;
}

export type ComponentType =
  | 'CHECKBOX_SELECT'
  | 'DATE_PICKER'
  | 'DROPDOWN_SELECT'
  | 'COMPONENT_GROUP'
  | 'MONTH_YEAR'
  | 'MULTI_LINE_TEXT'
  | 'RADIO_SELECT'
  | 'READ_ONLY_TEXT'
  | 'RESPIRATOR_SELECT'
  | 'SINGLE_LINE_TEXT'
  | 'SUMMARY_CARD_DISPLAY'
  | 'TOP_LEVEL_YES_NO'
  | 'YES_NO';

export interface Includable {
  include?: boolean | FunctionExpression;
}

export interface InputComponentDef extends ComponentDef {
  _schema: InputComponentSchema;
  props: InputComponentProps;
  type: InputComponentType;
}

export interface InputComponentProps extends ComponentProps {
  htmlId: string;
  rhfSetValue: UseFormSetValue<HtmlValues>;
  useFieldProps: UseFieldProps;
}

export interface InputComponentPropsSchema extends JSONObject {
  htmlId: string;
}

export interface InputComponentSchema extends ComponentSchema {
  props: InputComponentPropsSchema;
  required?: boolean | FunctionExpression;
  type: InputComponentType;
  validators?: Validator[];
}

export type InputComponentType =
  | 'CHECKBOX_SELECT'
  | 'DATE_PICKER'
  | 'DROPDOWN_SELECT'
  | 'MONTH_YEAR'
  | 'MULTI_LINE_TEXT'
  | 'RADIO_SELECT'
  | 'RESPIRATOR_SELECT'
  | 'SINGLE_LINE_TEXT'
  | 'TOP_LEVEL_YES_NO'
  | 'YES_NO';

export interface TextInputComponentSchema extends InputComponentSchema {
  pattern?: string;
}

export function isInputComponent(component: ComponentDef): component is InputComponentDef {
  return isInputComponentType(component.type);
}

export function isInputComponentSchema(schema: ComponentSchema): schema is InputComponentSchema {
  return isInputComponentType(schema.type);
}

export function isInputComponentType(type: ComponentType): type is InputComponentType {
  return [
    'CHECKBOX_SELECT',
    'DATE_PICKER',
    'DROPDOWN_SELECT',
    'MONTH_YEAR',
    'MULTI_LINE_TEXT',
    'RADIO_SELECT',
    'RESPIRATOR_SELECT',
    'SINGLE_LINE_TEXT',
    'TOP_LEVEL_YES_NO',
    'YES_NO',
  ].includes(type);
}

export function isTextInputSchema(schema: ComponentSchema): schema is TextInputComponentSchema {
  return schema.type === 'MULTI_LINE_TEXT' || schema.type === 'SINGLE_LINE_TEXT';
}
